<template>
  <div class="py-4">
    <b-card header="Bulk Upload Audio" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
      <div>
        <b-card class="bg-warning shadow-sm">
          <div class="row">
            <div class="col-md-1">
              <i class="fa fa-info-circle fa-4x" aria-hidden="true"></i>
            </div>
            <div class="col-md-10">
              <div class="text-dark">
                <ul>
                  <li>All audio file Must be compress and merge on .zip format</li>
                  <li>For each audio file, use chapter for filen ame ex: 1.mp3, 2.mp3</li>
                  <li>File name for .zip format is edition code ex: AUT_ID_HKI2020MTH03KCB.zip</li>
                  <li>Change the title and is sample after file have been successfully uploaded</li>
                  <li>One .zip file is for one edition code</li>
                  <li>Able to upload multiple edition code once</li>
                  <li> No Duplicate chapter</li>
                </ul>  
              </div>
            </div>
          </div>
        </b-card>
      </div>

      <div id="myDropzone" class="dropzone">
        <div v-if='!this.isUpload'>
          <vue-dropzone
            ref="DropZoneData"
            :include-styling="false"
            :useCustomSlot="true"
            id="dropzoneItems"
            :options="dropzoneOptions"
            @vdropzone-files-added="filesUpload"
            @vdropzone-file-added="fileAdded"
            >
            <div class="dropzone-container">
              <div class="file-selector">
                <icon></icon>
                <i class="bi bi-upload">Drop Files Here</i>
                <br/>
                <span> or </span>
                <br/>
                <button class="btn buttonBrowse" type="button">Browse</button>
              </div>
            </div>
          </vue-dropzone>
          </div>
          <div v-else>
            <div class="card" >
              <div class="card-body">
                <h5 class="card-title">  Status : {{ this.statusUpload }} </h5>
                <h6 >Proccesed : {{ this.indexUpload }} / {{ this.lengthUpload }}</h6>
                <h6  style="color:#0060AF">Success : {{ this.countRight }} / {{ this.lengthUpload }}</h6>
                <h6  style="color:#df4759">Failed : {{ this.countWrong }} / {{ this.lengthUpload }}</h6>
                <b-overlay
                :show="isOverlay"
                  rounded="lg"
                  opacity="0.6"
                  spinner-variant="primary"
                >
                  <AttachmentList
                    :tempAttachments="getTempAttachments"
                    :attachments="getAttachments"
                  />
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </b-card>
      <div v-if='this.isData'>
        <ReviewData
        :items="file_item"
        :type="'audiobook'">
        </ReviewData>
        <b-button class="fix-bot-right" size="lg" variant="warning"  @click="resetAction">
       Reset
      </b-button>
  </div>
</div>

</template>
<script>
import { mapActions } from 'vuex';
import { DetailItems } from '../../router/content';
import AttachmentList from '../../components/attachment/AttachmentList.vue';
import Icon from '../../components/attachment/icon.vue';
import ReviewData from '../../components/attachment/ReviewData.vue';
export default {
  name : 'UploadBulk',
  components: {
    Icon,
    AttachmentList,
    ReviewData,
  },
  data() {
    return {
      DetailItems,
      statusUpload:'Uploading',
      indexUpload:0,
      lengthUpload : 0,
      isData:false,
      isUpload:false,
      countWrong:0,
      countRight:0,
      file_item:[],
      isOverlay :false,
      tempAttachments: [],
      fields: [
        { key:'editionCode', label: 'Name (Edition Code)'},
        { key:'size', label: 'Size'},
        { key: 'extension', label: 'Extension' },
        { key: 'uploadStatus', label: 'Upload Status' },
        { key: 'message', label: 'Message' },
      ],
      dropzoneOptions: {
        url: `https://httpbin.org/post`, // this is neccessary but unused
        maxFilesize: 157286400, // 150 mega bytes
        paramName: function() {
          return "file[]";
        },
        includeStyling: false, 
        autoProcessQueue: false,
        previewsContainer: false, // to not set default dropzone buble box
        uploadMultiple: true,
        acceptedFiles: ".zip",
        parallelUploads: 20,
      }
    };
  },
  computed: {
    getTempAttachments() {
      return this.tempAttachments;
    },
    getAttachments() {
      return this.attachments;
    }
  },
  
  methods: {
    ...mapActions('bulkUpload', ['uploadAudioBulk','checkEditionCode','unzipFile']),
    ...mapActions("audioBooks", ["postNewChapter","updateStatusItems"]),
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },checkFiles() {
      this.isData = true;
    },
    async fileAdded() {
      const attachment = {};
      this.tempAttachments = [...this.tempAttachments, attachment];
      this.isUpload = true;
      this.isOverlay = true;
      // to add file into array of maps then continue upload in fileUpload()
    },
    async filesUpload(file) {
      this.lengthUpload = file.length;
      this.indexUpload = 0;
      for (const data of file) {
        this.indexUpload += 1;
        let messageAll = '';
        const attachment = {};
        let uploadStatus = false;
        let formatFile = data.type.split("/")[1];
        let editionCode = data.name.replace('.zip','');
        attachment.id = this.indexUpload;
        attachment.extension = "." + formatFile;
        attachment.size = this.formatBytes(data.size);
        attachment.name = data.name;
        attachment.editionCode = editionCode;
        attachment.nameItems = '';
        attachment.indexUpload  = this.indexUpload;
        attachment.lengthUpload = file.length;
        attachment.nameItems = null;
        attachment.item_id = null;
        if(formatFile!='x-zip-compressed') {
          messageAll = 'Wrong Format Upload';
          uploadStatus = false;
        }
        else {
          const items  = await this.checkEditionCode({editionCode:editionCode});
          if(items.result) {
            attachment.nameItems = items.name;
            attachment.item_id = items.id;
            const { resultbool, messageZip, flist }= await this.unzipFile(data);
            if(resultbool == true) {
              if(flist.length==0){
                messageAll = 'No Items Avaiable'
              } else {
                  const { res, messageNested } = await this.insertAudioBulk(items, flist);
                  messageAll = messageNested; // here is true
                  uploadStatus = res;
                  // update status to uploaded is manual in editChapter
                }
            } else {
              messageAll = messageZip;  
            }
          } else {
            messageAll = items.message;
          }
        }
        if(typeof messageAll == "string") {
          messageAll = [{messageItems: messageAll}]
        }
        attachment.message = messageAll;
        attachment.uploadStatus = uploadStatus;
        this.file_item.push(attachment);
        this.tempAttachments = [...this.tempAttachments, attachment];
        this.isData = true;
        if(uploadStatus==true){
          this.countRight += 1;
        } else{
          this.countWrong += 1;
        }
        if(this.indexUpload==file.length){
          break;
        }
      }
      return this.setEnds(this.tempAttachments);
    },
    async setEnds(tempAttachments) {
      this.isOverlay = false;
      this.statusUpload = 'Done Upload';
      const uploadStatus = tempAttachments.filter(object => {
          return object.uploadStatus ==  false;
      });
      if(uploadStatus.length==0) {
        return this.baseAlert({
          icon:'success',
          title: 'success',
          text: 'Success Bulk Upload',
        });
      } else {
        return this.baseAlert({
          icon:'error',
          title: 'Error',
          text: 'Something Error',
        });
      }
    },

    async insertAudioBulk(items, audioFile) {
      let res = false;
      let messageAll = '';
      const messageNested = [];
      audioFile = audioFile.sort(function(a, b) {
        return parseFloat(a.chapter) - parseFloat(b.chapter);
      });
      for (const audio of audioFile) {
        const maps = {};
        maps.item_id = items.id;
        maps.title = items.name;
        maps.file_chapter = audio.file_content;
        maps.chapter = audio.chapter;
        maps.is_sample = false;
        maps.file_count = audioFile.length;
        res = audio.result;
        messageAll = `Chapter ${audio.chapter} Status: ${audio.message} `;
        if(res == true) {
        const upload = await this.postNewChapter(maps);
          if(!upload.result) {
            res = upload.result;
            messageAll = `Chapter ${audio.chapter} Status: ${upload.message}  `;
          } else{
            messageAll = `Chapter ${audio.chapter} Status: ${upload.message}  `;
          }
        }
        const message = {};
        message.messageItems = messageAll;
        message.result = res;
        message.chapter = audio.chapter;
        this.tempAttachments = [...messageNested, message];
        messageNested.push(message);
      }
      return {res, messageNested};
    },
    baseAlert(data) {
      this.$swal({
        icon: data.icon,
        title: data.title,
        text: data.text,
    })
    },
    resetAction() {
      this.statusUpload='Uploading';
      this.indexUpload=0;
      this.lengthUpload = 0;
      this.isData=false;
      this.isUpload=false;
      this.file_item=[];
      this.countWrong=0;
      this.countRight=0;
      this.tempAttachments= [];
    },
  }
}
</script>
<style>
.file-selector {
  padding: 55px;
  font-weight: 600;
  background-color: #f9f9f9;
  color: #4e5b69;
  width: 100%;
  height: 100%;
  
}
.buttonBrowse {
  background: #2B65EC;
  box-shadow: 0 0 2px 0 rgba(3, 22, 41, 0.11),
    0 6px 16px 0 rgba(3, 22, 41, 0.08);
  font-family: SFProDisplay-Regular;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.4px;
  padding: 12px 30px;
  border-radius: 4px;
}
.dropzone-container {
  display: flex;
  flex-direction: column;
  border: 1px dashed #ccc;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.separator {
  position: relative;
}
.separator:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 200px;
  background: #d8d8d8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.span {
  position: relative;
  background: #f9f9f9;
  padding: 0 4px;
  font-size: 12px;
  color: red;
}
.fix-bot-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 0 10px white;
}

</style>
