var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-4"
  }, [_c('b-card', {
    attrs: {
      "header": "Bulk Upload Audio",
      "header-bg-variant": "primary",
      "header-tag": "h5",
      "header-class": "font-weight-bold"
    }
  }, [_c('div', [_c('b-card', {
    staticClass: "bg-warning shadow-sm"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-1"
  }, [_c('i', {
    staticClass: "fa fa-info-circle fa-4x",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c('div', {
    staticClass: "col-md-10"
  }, [_c('div', {
    staticClass: "text-dark"
  }, [_c('ul', [_c('li', [_vm._v("All audio file Must be compress and merge on .zip format")]), _c('li', [_vm._v("For each audio file, use chapter for filen ame ex: 1.mp3, 2.mp3")]), _c('li', [_vm._v("File name for .zip format is edition code ex: AUT_ID_HKI2020MTH03KCB.zip")]), _c('li', [_vm._v("Change the title and is sample after file have been successfully uploaded")]), _c('li', [_vm._v("One .zip file is for one edition code")]), _c('li', [_vm._v("Able to upload multiple edition code once")]), _c('li', [_vm._v(" No Duplicate chapter")])])])])])])], 1), _c('div', {
    staticClass: "dropzone",
    attrs: {
      "id": "myDropzone"
    }
  }, [!this.isUpload ? _c('div', [_c('vue-dropzone', {
    ref: "DropZoneData",
    attrs: {
      "include-styling": false,
      "useCustomSlot": true,
      "id": "dropzoneItems",
      "options": _vm.dropzoneOptions
    },
    on: {
      "vdropzone-files-added": _vm.filesUpload,
      "vdropzone-file-added": _vm.fileAdded
    }
  }, [_c('div', {
    staticClass: "dropzone-container"
  }, [_c('div', {
    staticClass: "file-selector"
  }, [_c('icon'), _c('i', {
    staticClass: "bi bi-upload"
  }, [_vm._v("Drop Files Here")]), _c('br'), _c('span', [_vm._v(" or ")]), _c('br'), _c('button', {
    staticClass: "btn buttonBrowse",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("Browse")])], 1)])])], 1) : _c('div', [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "card-title"
  }, [_vm._v(" Status : " + _vm._s(this.statusUpload) + " ")]), _c('h6', [_vm._v("Proccesed : " + _vm._s(this.indexUpload) + " / " + _vm._s(this.lengthUpload))]), _c('h6', {
    staticStyle: {
      "color": "#0060AF"
    }
  }, [_vm._v("Success : " + _vm._s(this.countRight) + " / " + _vm._s(this.lengthUpload))]), _c('h6', {
    staticStyle: {
      "color": "#df4759"
    }
  }, [_vm._v("Failed : " + _vm._s(this.countWrong) + " / " + _vm._s(this.lengthUpload))]), _c('b-overlay', {
    attrs: {
      "show": _vm.isOverlay,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    }
  }, [_c('AttachmentList', {
    attrs: {
      "tempAttachments": _vm.getTempAttachments,
      "attachments": _vm.getAttachments
    }
  })], 1)], 1)])])])]), this.isData ? _c('div', [_c('ReviewData', {
    attrs: {
      "items": _vm.file_item,
      "type": 'audiobook'
    }
  }), _c('b-button', {
    staticClass: "fix-bot-right",
    attrs: {
      "size": "lg",
      "variant": "warning"
    },
    on: {
      "click": _vm.resetAction
    }
  }, [_vm._v(" Reset ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }